<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<headerNav :isScrolled="isScrolled" :currentPath="currentPath">
			</headerNav>
			<div class="pd_banner">
				<div class="pd_banner_img">
					<img
						class="pd_banner_img_1"
						src="https://res.metaera.hk/resources/assets/images/event/44.png"
						alt=""
					/>
					<img
						class="pd_banner_img_2"
						src="https://res.metaera.hk/resources/assets/images/event/43.png"
						alt=""
					/>
					<div class="pd_banner_img_overlay"></div>
				</div>
				<div class="pd_banner_20">STAY TUNED FOR OUR SPEAKER LINEUP!</div>
				<div class="pd_banner_21">
					Polkadot Decoded Asia 2024 brings together numerous speakers from the
					cryptocurrency field, including top developers, seasoned investors,
					enthusiasts, and industry leaders. The event focuses on cutting-edge
					technologies and innovative applications within the Polkadot
					ecosystem, offering deep discussions and inspiration for attendees.
					<span @click="sendEmail()">event@metaera.hk</span>.</div
				>
			</div>
			<people :isAll="true"></people>
			<bottom></bottom>
			<div class="demo" style="height: 50px"></div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";
import pdintroduceVue from "./pdintroduce.vue";
import people from "./pdpeople.vue";

export default {
	name: "",
	components: {
		headerNav,
		bottom,
		people,
		pdintroduceVue,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			isScrolled: true,
			currentPath: "/polkadot2024_speaker",
		};
	},
	created() {},
	methods: {
		handleScroll(event) {
			this.isScrolled = true;
		},
		sendEmail() {
			const email = "event@metaera.hk";
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;

	background: #f7f8f9;
}
</style>

<!-- banner -->
<style scoped>
.pd_banner {
	margin-top: 80px;
	width: 100vw;
	height: calc(100vw * (678 / 2560));
}

.pd_banner_img {
	width: 100%;
	height: auto;
}

.pd_banner_img_1 {
	display: flex;
}

.pd_banner_img_2 {
	display: none;
}
.pd_banner_img img {
	width: 100%;
	height: 100%;
}
.pd_banner_bottom {
	position: absolute;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -40px;
	height: 40px;
	width: 90%;
	background: white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	z-index: 10;
	display: none;
}
.pd_banner_20 {
	margin-top: 80px;
	position: absolute;
	left: calc(100vw * 40 / 1280);
	right: calc(100vw * 40 / 1280);
	top: calc(100vw * 40 / 1280);
	font-family: DINPro-Bold;
	font-size: calc(100vw * 60 / 1280);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	text-align: center;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_banner_21 {
	margin-top: 80px;
	position: absolute;
	left: calc(100vw * 90 / 1280);
	right: calc(100vw * 90 / 1280);
	top: calc(100vw * 150 / 1280);
}
.pd_banner_21,
.pd_banner_21 span {
	font-family: D-DIN;
	font-size: calc(100vw * 20 / 1280);
	text-align: center;
	letter-spacing: 0em;
	line-height: 1.2;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.pd_banner_21 span {
	text-decoration: underline;
}
.pd_banner_img_overlay {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	height: calc(100vw * (678 / 2560));
	opacity: 0.6;
	background: black;
}
@media screen and (max-width: 900px) {
	.pd_banner_img_overlay {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vw * 660 / 1125);
		opacity: 0.6;
		background: black;
	}
	.pd_banner {
		margin-top: 50px;
		height: calc(100vw * 660 / 1125);
	}
	.pd_banner_img {
		width: 100%;
		height: 100%;
	}

	.pd_banner_img_1 {
		display: none;
	}

	.pd_banner_img_2 {
		display: flex;
	}
	.pd_banner_bottom {
		display: flex;
	}

	.pd_banner_20 {
		position: absolute;
		margin-top: 50px;
		left: calc(100vw * 50 / 375);
		right: calc(100vw * 50 / 375);
		top: calc(100vw * 20 / 375);
		font-family: DINPro-Bold;
		font-size: calc(100vw * 20 / 375);
		font-weight: bold;
		line-height: normal;
		letter-spacing: 0em;
		text-align: center;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}

	.pd_banner_21 {
		position: absolute;
		margin-top: 50px;
		left: calc(100vw * 30 / 375);
		top: calc(100vw * 80 / 375);
	}

	.pd_banner_21,
	.pd_banner_21 span {
		font-family: D-DIN;
		font-size: calc(100vw * 12 / 375);
		text-align: center;
		line-height: 1.2;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>
